import {
  mdiBullseyeArrow,
  mdiCar,
  mdiCogOutline,
  mdiFileChartOutline,
  mdiLightbulbOnOutline,
  mdiTelevision,
  mdiTextBoxSearchOutline,
} from '@mdi/js'

import localStorageSlim from 'localstorage-slim'

const userProfile = localStorageSlim.get('userProfile', { decrypt: true })
const profileName = userProfile?.profile?.profile
const occupation = userProfile?.occupation_sector?.occupation?.name

const utilities = [
  {
    subheader: 'Utilitários',
  },

  // {
  //   title: 'CAMPANHAS',
  //   icon: mdiSendOutline,
  //   children: [
  //     {
  //       title: '1° Revisão',
  //       to: 'utilities-home',
  //       hidden: true,
  //     },
  //   ],
  // },
]

if (profileName === 'Administrativo' || profileName === 'Pós-venda' || profileName === 'Administrador') {
  utilities.push({
    title: 'PEÇAS',
    icon: mdiCogOutline,
    children: [
      {
        title: 'Separar',
        to: 'parts-order',
        hidden: false,
      },
    ],
  })
}
if (profileName === 'Auditoria' || profileName === 'Administrador' || profileName === 'Controladoria' || profileName === 'Diretor') {
  utilities.push({
    title: 'LOGS',
    icon: mdiTextBoxSearchOutline,
    children: [
      {
        title: 'Erro',
        to: 'logs-error',
        hidden: true,
      },
      {
        title: 'Sucesso',
        to: 'logs-success',
        hidden: true,
      },
    ],
  },
  {
    title: 'INTEGRAÇÕES',
    icon: mdiFileChartOutline,
    children: [
      {
        title: 'ALL STRATEGY',
        icon: mdiLightbulbOnOutline,
        hidden: false,
        children: [
          {
            title: 'REPROCESSAMENTO',
            to: 'integrations-reprocessing',
            hidden: false,
          },
          {
            title: 'EXCEÇÕES',
            to: 'black-list',
            hidden: false,
          },
        ],
      },
      {
        title: 'AUTO FORCE',
        icon: mdiCar,
        hidden: false,
        children: [
          {
            title: 'Catálogo site',
            hidden: false,
            to: 'auto-force-catalog',
          },
        ],
      },
    ],
  })
}

if (profileName === 'Administrador' || profileName === 'Diretor') {
  utilities.push(
    {
      title: 'INTEGRAÇÕES',
      icon: mdiFileChartOutline,
      children: [
        {
          title: 'ALL STRATEGY',
          icon: mdiLightbulbOnOutline,
          hidden: false,
          children: [
            {
              title: 'REPROCESSAMENTO',
              to: 'integrations-reprocessing',
              hidden: false,
            },
          ],
        },
        {
          title: 'AUTO FORCE',
          icon: mdiCar,
          hidden: false,
          children: [
            {
              title: 'CATÁLOGO - MOTOCA',
              hidden: false,
              to: 'auto-force-catalog-motoca',
            },
            {
              title: 'CATÁLOGO - FUJI',
              hidden: false,
              to: 'auto-force-catalog-fuji',
            },
          ],
        },
      ],
    },
  )
}
if (profileName === 'Semi Novos') {
  utilities.push(
    {
      title: 'INTEGRAÇÕES',
      icon: mdiFileChartOutline,
      children: [
        {
          title: 'AUTO FORCE',
          icon: mdiCar,
          hidden: false,
          children: [
            {
              title: 'CATÁLOGO - MOTOCA',
              hidden: false,
              to: 'auto-force-catalog-motoca',
            },
            {
              title: 'CATÁLOGO - FUJI',
              hidden: false,
              to: 'auto-force-catalog-fuji',
            },
          ],
        },
      ],
    },
  )
}
if (profileName === 'GERENTE GERAL' || occupation === 'AUX ADMINISTRATIVO PRÉ-VENDA' || occupation === 'CONSULTOR DE VENDAS SEMI-NOVOS') {
  utilities.push(
    {
      title: 'INTEGRAÇÕES',
      icon: mdiFileChartOutline,
      children: [
        {
          title: 'AUTO FORCE',
          icon: mdiCar,
          hidden: false,
          children: [
            {
              title: 'CATÁLOGO - FUJI',
              hidden: false,
              to: 'auto-force-catalog-fuji',
            },
          ],
        },
      ],
    },
  )
}

// if (profileName === 'Administrador' || profileName === 'Diretor' || profileName === 'Semi Novos') {
//   utilities.push({
//     title: 'AUTO FORCE',
//     icon: mdiCar,
//     children: [
//       {
//         title: 'Catálogo site',
//         hidden: true,
//         to: 'auto-force-catalog',
//       },
//     ],
//   })
// }

// const verifyIsMarketing = profileName === 'Marketing' || profileName === 'Administrador' || profileName === 'Diretor'

if (profileName === 'Marketing' || profileName === 'Administrador' || profileName === 'Diretor' || occupation === 'COORD ADM VENDAS DE CONSORCIO') {
  utilities.push({
    title: 'MARKETING',
    icon: mdiBullseyeArrow,
    children: [
      {
        title: 'NEXUS PLAYER',
        icon: mdiTelevision,
        hidden: true,
        children: [
          {
            title: 'Inserir mídias',
            to: 'insert-media',
            hidden: true,
          },
          {
            title: 'Exibição',
            to: 'display-media',
            hidden: true,
          },
        ],
      },
    ],
  })
} else {
  utilities.push({
    title: 'MARKETING',
    icon: mdiBullseyeArrow,
    children: [
      {
        title: 'NEXUS PLAYER',
        icon: mdiTelevision,
        hidden: true,
        children: [
          {
            title: 'Exibição',
            to: 'display-media',
            hidden: true,
          },
        ],
      },
    ],
  })
}

export default utilities
